$iot-prefix: 'glow';

.#{$iot-prefix}--card--toolbar-date-range-wrapper {
  background: transparent;
  border: none;
  display: flex;
  outline: 2px solid transparent;
}

.#{$iot-prefix}--card--toolbar-timerange-label {
  white-space: nowrap;
  min-height: $carbon--spacing-09;
  min-width: $carbon--spacing-09;
  padding: $carbon--spacing-05 $carbon--spacing-03;
  font-size: 0.875rem;
  font-weight: normal;
}

.#{$iot-prefix}--card--toolbar-date-range-wrapper
  > .#{$iot-prefix}--card--toolbar-date-range-action {
  min-height: $carbon--spacing-09;
  min-width: $carbon--spacing-09;
  cursor: pointer;
  &:active,
  &:focus {
    outline: 2px solid $focus;
    outline-offset: -2px;
  }
  &:hover {
    background: $hover-ui;
  }
}

.#{$iot-prefix}--card--overflow {
  width: auto;
  &.#{$prefix}--overflow-menu-options[data-floating-menu-direction='bottom']::after {
    width: 3rem; // since the button is wider, the overlay needs to be wider to match
  }
}

.#{$iot-prefix}--card--overflow-menuitem-active:not(:hover) {
  background-color: $active-ui;
}