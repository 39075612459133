@mixin multiline-text-overflow($max-lines: 3, $line-height-rem: 1) {
    overflow: hidden;
  
    // Fallback solution for users where -webkit-line-clamp doesn't work
    max-height: calc(#{$line-height-rem} * (#{$max-lines} + 0.5));
    display: block;
  
    // Use webkit-line-clamp which despite webkit prefix has good browser support
    display: -webkit-box;
    -webkit-line-clamp: $max-lines;
    // Not needed after we upgrade to Prefixer 9.6.1 or higher
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
  
  @mixin disabled-icon-button() {
    cursor: not-allowed;
    svg {
      fill: $disabled;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
  
  @mixin content-switcher-border-radius($border-radius, $first-button-side, $last-button-side) {
    button:first-of-type,
    button:last-of-type {
      border-radius: 0;
    }
  
    button:first-of-type {
      border-top-#{$first-button-side}-radius: $border-radius;
      border-bottom-#{$first-button-side}-radius: $border-radius;
    }
    button:last-of-type {
      border-top-#{$last-button-side}-radius: $border-radius;
      border-bottom-#{$last-button-side}-radius: $border-radius;
    }
}