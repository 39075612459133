// To change the theme uncomment the below import and which ever carbon theme you want to use
@import '~carbon-components/scss/globals/scss/vendor/@carbon/elements/scss/themes/theme-maps';

// $carbon--theme: $carbon--theme--g100;
// $carbon--theme: $carbon--theme--g90;
 $carbon--theme: $carbon--theme--g10;

@import "~carbon-components/scss/globals/scss/styles";
@import "@carbon/charts/styles/styles.scss";

@import "./assets/scss/carbon-override.scss";
@import "./assets/scss/card/card.component.scss";

@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';