$iot-prefix: 'glow';

.#{$iot-prefix}--data-state-container {
  // the custom property (CSS var) is defined in the Card that is using it
  padding: 0 var(--container-padding);
  width: inherit;
}

.#{$iot-prefix}--data-state-dashes {
  color: $gray-20;
  font-size: 4rem;
  line-height: 0.67;
}

.#{$iot-prefix}--data-state-grid {
  margin-top: $spacing-05;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 1fr;
  grid-column-gap: 0;
  grid-template-areas:
    'app-icon  app-label             .        '
    '    .     app-description app-description';

  > .#{$prefix}--tooltip__label {
    overflow: hidden;
    &:focus {
      outline: none;
    }
  }

  & > *:first-child {
    grid-area: app-icon;
    cursor: pointer;
    margin-right: $spacing-03;
  }

  & > *:nth-child(2) {
    grid-area: app-label;

    // We want app label to use ellipsis for longer texts and
    // we can't have overflow:hidden due to tooltip scrolling.
    // This shrinks the width to fit the app-label grid area.
    .bx--tooltip__label {
      display: grid;
    }
  }

  & > *:nth-child(3) {
    grid-area: app-description;
  }
}

.#{$iot-prefix}--data-state-grid__label {
  @include type-style('productive-heading-01');
  @include text-overflow();
}

.#{$iot-prefix}--data-state-grid__description {
  @include type-style('helper-text-01');
  color: $gray-70;

  $max-lines: 2;
  $line-height: 1rem;
  @include multiline-text-overflow($max-lines, $line-height);
}

.#{$iot-prefix}--data-state-tooltip:focus {
  outline: none;
}

.#{$iot-prefix}--data-state-tooltip {
  > * {
    margin-bottom: $spacing-05;
  }

  .#{$iot-prefix}--data-state-tooltip__label {
    @include type-style('productive-heading-01');
  }
}

.#{$iot-prefix}--data-state-default-error-icon {
  fill: $carbon--red-60;
}

.#{$iot-prefix}--data-state-default-warning-icon {
  fill: $carbon--yellow-30;
}

html[dir='rtl'] .#{$iot-prefix}--data-state-grid {
  & > *:first-child {
    margin-right: 0;
    margin-left: $spacing-03;
  }
}