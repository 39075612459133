$iot-prefix: 'glow';

.#{$iot-prefix}--card--toolbar {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  // shrink the default text of the date-time-picker
  .#{$iot-prefix}--date-time-picker__field {
    font-size: 0.875rem;
    font-weight: normal;
  }
}

.#{$iot-prefix}--card--toolbar-action {
  color: $ui-05;
  border-width: 2px;
  display: flex;
  height: 2rem;
  justify-content: center;
  min-height: 2rem;
  min-width: 2rem;
  width: 2rem;

  &:hover {
    background-color: $hover-ui;
    color: $ui-05;
  }

  &:focus {
    border-color: transparent;
    outline: 2px solid $focus;
    outline-offset: -2px;
  }

  svg {
    fill: $icon-02;
  }

  svg.#{$prefix}--overflow-menu__icon {
    height: auto;
    width: auto;
  }
}

.#{$iot-prefix}--card--toolbar-svg-wrapper {
  align-items: center;
  background: transparent;
  border: none;
  display: flex;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  outline: 2px solid transparent;
  margin: 0;

  :hover {
    background: $hover-ui;
  }

  &:active {
    outline: 2px solid $focus;
    outline-offset: -2px;
  }
}